<template>
  <div>
    <Header />
    <section class="bg-light d-none d-lg-block">
      <div class="container py-4">
        <div class="bg-white p-4 rounded-10">
          <div class="d-flex justify-content-start w-100 gap-3">
            <img
              :src="collectionDetails.logo"
              class="rounded-10"
              width="230"
              height="230"
              alt=""
            />

            <div class="p-3 w-100">
              <h3 class="fw-bold">
                {{ collectionDetails.name }}
              </h3>
              <p
                v-html="collectionDetails.description"
                class="text-secondary"
              ></p>
              <div class="d-flex justify-content-start gap-2">
                <a
                  :href="collectionDetails.collection?.external_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-global-line"></em
                ></a>
                <a
                  :href="collectionDetails.collection?.chat_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-message-line"></em
                ></a>
                <a
                  :href="collectionDetails.collection?.discord_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-discord-line"></em
                ></a>
                <a
                  :href="
                    'https://instagram.com/' +
                    collectionDetails.collection?.instagram_username
                  "
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-instagram-line"></em
                ></a>
                <a
                  :href="
                    'https://medium.com/@' +
                    collectionDetails.collection?.medium_username
                  "
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-medium-line"></em
                ></a>
                <a
                  :href="
                    'https://twitter.com/' +
                    collectionDetails.collection?.twitter_username
                  "
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-twitter-line"></em
                ></a>
                <a
                  :href="collectionDetails.collection?.telegram_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-telegram-line"></em
                ></a>
                <a
                  :href="collectionDetails.collection?.wiki_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-search-line"></em
                ></a>
              </div>
            </div>
          </div>
          <h5 class="mt-3 fw-bold">{{ collectionDetails.name }} Statistic</h5>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Users</h6>
                      <div class="d-flex justify-content-between gap-3">
                        <div>
                          <h5 class="fw-bold">
                            {{ nftStatistic.userActivity | formatNumber }}
                          </h5>
                          <div>{{ nftStatistic.userActivityChange }}%</div>
                        </div>
                        <img
                          v-if="nftStatistic.activeUsersChart != null"
                          :src="nftStatistic.activeUsersChart"
                          alt=""
                          class="w-50"
                          style="max-height: 35px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Transaction</h6>
                      <div class="d-flex justify-content-between gap-3">
                        <div>
                          <h5 class="fw-bold">
                            {{ nftStatistic.transactionCount | formatNumber }}
                          </h5>
                          <div>{{ nftStatistic.transactionChange }}%</div>
                        </div>
                        <img
                          v-if="nftStatistic.transactionsChart != null"
                          :src="nftStatistic.transactionsChart"
                          alt=""
                          class="w-50"
                          style="max-height: 35px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Volume</h6>
                      <div class="d-flex justify-content-between gap-3">
                        <div>
                          <h5 class="fw-bold">
                            ${{ nftStatistic.totalVolumeInFiat | formatNumber }}
                          </h5>
                          <div>
                            {{ Number(nftStatistic.volume).toFixed(0) }}
                          </div>
                          <div>{{ nftStatistic.totalVolumeChangeInFiat }}%</div>
                        </div>
                        <img
                          v-if="nftStatistic.volumeInFiatChart != null"
                          :src="nftStatistic.volumeInFiatChart"
                          alt=""
                          class="w-50"
                          style="max-height: 35px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Balance</h6>
                      <div class="d-flex justify-content-between gap-3">
                        <div>
                          <h5 class="fw-bold">
                            ${{
                              nftStatistic.totalBalanceInFiat | formatNumber
                            }}
                          </h5>
                          <div>
                            {{ Number(nftStatistic.balance).toFixed(5) }}
                            {{ nftStatistic.coinName }}
                          </div>
                          <div>
                            {{ nftStatistic.totalBalanceChangeInFiat }}%
                          </div>
                        </div>
                        <img
                          v-if="nftStatistic.balanceFiatChart != null"
                          :src="nftStatistic.balanceFiatChart"
                          alt=""
                          class="w-50"
                          style="max-height: 35px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <apexchart
                type="line"
                ref="collectionsChart"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </div>

          <h5 class="mt-3 fw-bold">Collection Statistic</h5>
          <div class="row mt-3">
            <div class="col-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Volume</h6>
                    <h5 class="fw-bold">
                      ${{ statistic.volumeInFiat | formatNumber }}
                    </h5>
                    <p class="mb-0">{{ statistic.volumeChange }}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Traders</h6>
                    <h5 class="fw-bold">{{ statistic.tradersCount }}</h5>
                    <p class="mb-0">{{ statistic.tradersCountChange }}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Sales</h6>
                    <h5 class="fw-bold">{{ statistic.salesCount }}</h5>
                    <p class="mb-0">{{ statistic.salesCountChange }}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Marketcap</h6>
                    <h5 class="fw-bold">
                      ${{ statistic.marketCapInFiat | formatNumber }}
                    </h5>
                    <p class="mb-0">{{ statistic.marketCapChange }}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Floor Price</h6>
                    <h5 class="fw-bold">
                      {{ statistic.floorPriceInFiat | formatNumber }}
                    </h5>
                    <p class="mb-0">{{ statistic.floorPriceChange }}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>AVG. Price</h6>
                    <h5 class="fw-bold">
                      {{ statistic.avgPriceInFiat | formatNumber }}
                    </h5>
                    <p class="mb-0">{{ statistic.avgPriceChange }}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h5 class="mt-3 fw-bold">Top Sales</h5>
          <div class="card mt-4">
            <div class="card-body">
              <table class="table table-striped log-table-new">
                <thead>
                  <tr>
                    <td></td>
                    <td>NFT</td>
                    <td>Time</td>
                    <td class="text-end">Price</td>
                    <td class="text-end">Transfer</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(transaction, index) in sales"
                    :key="index"
                    class="align-middle"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>
                      <div
                        class="
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-3
                        "
                      >
                        <img
                          :src="transaction.logo"
                          class="rounded-10"
                          width="64"
                          height="64"
                          alt=""
                        />
                        <div class="d-flex flex-column">
                          <h6>{{ transaction.name }}</h6>
                          <div class="d-flex justify-content-start gap-2">
                            <img
                              :src="transaction.collectionLogo"
                              class="rounded-3"
                              width="20"
                              height="20"
                              alt=""
                            />
                            <div>{{ transaction.collectionName }}</div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {{ moment(transaction.soldAt).fromNow() }}
                    </td>
                    <td class="text-end">
                      <div class="d-flex flex-column">
                        <div>${{ transaction.priceInFiat | formatNumber }}</div>
                        <small class="text-secondary">
                          {{ transaction.priceInCrypto }}
                          {{ transaction.tokenSymbol }}
                        </small>
                      </div>
                    </td>
                    <td class="text-end">
                      <div class="d-flex justify-content-end gap-2">
                        <div>
                          {{ transaction.seller | truncate(6, "...", 50) }}
                        </div>
                        <div><em class="ri-file-transfer-line"></em></div>
                        <div>
                          {{ transaction.buyer | truncate(6, "...", 50) }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="d-lg-none d-block px-2 mb-3">
      <div class="card margin-t-10 border-0">
        <div class="card-body">
          <div
            class="d-flex justify-content-start align-items-center w-100 gap-2"
          >
            <img
              :src="collectionDetails.logo"
              class="rounded-10"
              width="65"
              height="65"
              alt=""
            />

            <div class="p-2 w-100">
              <h3 class="fw-bold">
                {{ collectionDetails.name }}
              </h3>
              <div class="d-flex justify-content-start gap-2">
                <a
                  :href="collectionDetails.collection?.external_url"
                  class="text-decoration-none h4 text-dark mb-0"
                  ><em class="ri-global-line"></em
                ></a>
                <a
                  :href="collectionDetails.collection?.chat_url"
                  class="text-decoration-none h4 text-dark mb-0"
                  ><em class="ri-message-line"></em
                ></a>
                <a
                  :href="collectionDetails.collection?.discord_url"
                  class="text-decoration-none h4 text-dark mb-0"
                  ><em class="ri-discord-line"></em
                ></a>
                <a
                  :href="
                    'https://instagram.com/' +
                    collectionDetails.collection?.instagram_username
                  "
                  class="text-decoration-none h4 text-dark mb-0"
                  ><em class="ri-instagram-line"></em
                ></a>
                <a
                  :href="
                    'https://medium.com/@' +
                    collectionDetails.collection?.medium_username
                  "
                  class="text-decoration-none h4 text-dark mb-0"
                  ><em class="ri-medium-line"></em
                ></a>
                <a
                  :href="
                    'https://twitter.com/' +
                    collectionDetails.collection?.twitter_username
                  "
                  class="text-decoration-none h4 text-dark mb-0"
                  ><em class="ri-twitter-line"></em
                ></a>
                <a
                  :href="collectionDetails.collection?.telegram_url"
                  class="text-decoration-none h4 text-dark mb-0"
                  ><em class="ri-telegram-line"></em
                ></a>
                <a
                  :href="collectionDetails.collection?.wiki_url"
                  class="text-decoration-none h4 text-dark mb-0"
                  ><em class="ri-search-line"></em
                ></a>
              </div>
            </div>
          </div>

          <p class="mt-3" v-html="collectionDetails.description"></p>

          <h5 class="mt-3 fw-bold">{{ collectionDetails.name }} Statistic</h5>
          <div class="row mt-3">
            <div class="col-12">
              <div
                class="d-flex justify-content-start gap-3 w-100 custom-scroll"
              >
                <div class="col-10">
                  <div class="card">
                    <div class="card-body">
                      <h6>Users</h6>
                      <div class="d-flex justify-content-between gap-3">
                        <div>
                          <h5 class="fw-bold">
                            {{ nftStatistic.userActivity | formatNumber }}
                          </h5>
                          <div>{{ nftStatistic.userActivityChange }}%</div>
                        </div>
                        <img
                          v-if="nftStatistic.activeUsersChart != null"
                          :src="nftStatistic.activeUsersChart"
                          alt=""
                          class="w-50"
                          style="max-height: 35px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="card">
                    <div class="card-body">
                      <h6>Transaction</h6>
                      <div class="d-flex justify-content-between gap-3">
                        <div>
                          <h5 class="fw-bold">
                            {{ nftStatistic.transactionCount | formatNumber }}
                          </h5>
                          <div>{{ nftStatistic.transactionChange }}%</div>
                        </div>
                        <img
                          v-if="nftStatistic.transactionsChart != null"
                          :src="nftStatistic.transactionsChart"
                          alt=""
                          class="w-50"
                          style="max-height: 35px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="card">
                    <div class="card-body">
                      <h6>Volume</h6>
                      <div class="d-flex justify-content-between gap-3">
                        <div>
                          <h5 class="fw-bold">
                            ${{ nftStatistic.totalVolumeInFiat | formatNumber }}
                          </h5>
                          <div>
                            {{ Number(nftStatistic.volume).toFixed(0) }}
                          </div>
                          <div>{{ nftStatistic.totalVolumeChangeInFiat }}%</div>
                        </div>
                        <img
                          v-if="nftStatistic.volumeInFiatChart != null"
                          :src="nftStatistic.volumeInFiatChart"
                          alt=""
                          class="w-50"
                          style="max-height: 35px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="card">
                    <div class="card-body">
                      <h6>Balance</h6>
                      <div class="d-flex justify-content-between gap-3">
                        <div>
                          <h5 class="fw-bold">
                            ${{
                              nftStatistic.totalBalanceInFiat | formatNumber
                            }}
                          </h5>
                          <div>
                            {{ Number(nftStatistic.balance).toFixed(5) }}
                            {{ nftStatistic.coinName }}
                          </div>
                          <div>
                            {{ nftStatistic.totalBalanceChangeInFiat }}%
                          </div>
                        </div>
                        <img
                          v-if="nftStatistic.balanceFiatChart != null"
                          :src="nftStatistic.balanceFiatChart"
                          alt=""
                          class="w-50"
                          style="max-height: 35px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <apexchart
                type="line"
                ref="collectionsChart"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </div>

          <h5 class="mt-3 fw-bold">Collection Statistic</h5>
          <div class="d-flex justify-content-start gap-3 w-100 custom-scroll">
            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Volume</h6>
                    <h5 class="fw-bold">
                      ${{ statistic.volumeInFiat | formatNumber }}
                    </h5>
                    <p class="mb-0">{{ statistic.volumeChange }}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Traders</h6>
                    <h5 class="fw-bold">{{ statistic.tradersCount }}</h5>
                    <p class="mb-0">{{ statistic.tradersCountChange }}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Sales</h6>
                    <h5 class="fw-bold">{{ statistic.salesCount }}</h5>
                    <p class="mb-0">{{ statistic.salesCountChange }}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-start gap-3 w-100 custom-scroll mt-3"
          >
            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Marketcap</h6>
                    <h5 class="fw-bold">
                      ${{ statistic.marketCapInFiat | formatNumber }}
                    </h5>
                    <p class="mb-0">{{ statistic.marketCapChange }}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>Floor Price</h6>
                    <h5 class="fw-bold">
                      {{ statistic.floorPriceInFiat | formatNumber }}
                    </h5>
                    <p class="mb-0">{{ statistic.floorPriceChange }}%</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start">
                    <h6>AVG. Price</h6>
                    <h5 class="fw-bold">
                      {{ statistic.avgPriceInFiat | formatNumber }}
                    </h5>
                    <p class="mb-0">{{ statistic.avgPriceChange }}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h5 class="mt-3 fw-bold">Top Sales</h5>
          <div class="mt-4">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <td></td>
                    <td>NFT</td>
                    <td>Time</td>
                    <td class="text-end">Price</td>
                    <td class="text-end">Transfer</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(transaction, index) in sales"
                    :key="index"
                    class="align-middle"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>
                      <div
                        class="
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-3
                        "
                      >
                        <img
                          :src="transaction.logo"
                          class="rounded-10"
                          width="64"
                          height="64"
                          alt=""
                        />
                        <div class="d-flex flex-column">
                          <h6 class="text-nowrap">{{ transaction.name }}</h6>
                          <div class="d-flex justify-content-start gap-2">
                            <img
                              :src="transaction.collectionLogo"
                              class="rounded-3"
                              width="20"
                              height="20"
                              alt=""
                            />
                            <div class="text-nowrap">
                              {{ transaction.collectionName }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="text-nowrap">
                        {{ moment(transaction.soldAt).fromNow() }}
                      </div>
                    </td>
                    <td class="text-end">
                      <div class="d-flex flex-column">
                        <div>${{ transaction.priceInFiat | formatNumber }}</div>
                        <small class="text-secondary">
                          {{ transaction.priceInCrypto }}
                          {{ transaction.tokenSymbol }}
                        </small>
                      </div>
                    </td>
                    <td class="text-end">
                      <div class="d-flex justify-content-end gap-2">
                        <div>
                          {{ transaction.seller | truncate(6, "...", 50) }}
                        </div>
                        <div><em class="ri-file-transfer-line"></em></div>
                        <div>
                          {{ transaction.buyer | truncate(6, "...", 50) }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import moment from "moment";

export default {
  name: "NftCollection",
  components: { Header, Footer, apexchart: VueApexCharts },
  data() {
    return {
      collectionDetails: [],
      collectionStats: [],
      statistic: [],
      sales: [],
      nftStatistic: [],
      moment: moment,
      series: [
        {
          name: "Income",
          type: "line",
          data: [760, 1661, 1371, 1748, 1478, 1475],
        },
        {
          name: "Cashflow",
          type: "line",
          data: [
            6571235.87, 11809432.28, 19238482.56, 19266759.93, 17298532.28,
            15804438.25,
          ],
        },
        {
          name: "Revenue",
          type: "line",
          data: [1103, 2749, 2263, 3316, 2365, 2400],
        },
      ],
      chartOptions: {
        chart: {
          stacked: false,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: -20,
          },
        },
        grid: {
          padding: {
            bottom: 0,
            left: 14,
            right: 0,
            top: 0,
          },
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [2, 2, 2],
          curve: "smooth",
        },
        title: {
          text: "XYZ - Stock Analysis (2009 - 2016)",
          align: "left",
          offsetX: 110,
        },
        xaxis: {
          axisBorder: { color: "#EFEFEF", show: true },
          categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            style: { color: "#C3C3C3" },
          },
          type: "datetime",
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            decimalsInFloat: 0,
            show: true,
            axisBorder: {
              show: true,
              color: "#008FFB",
            },
            labels: {
              style: {
                colors: "#008FFB",
              },
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            title: {
              text: "Income (thousand crores)",
              style: {
                color: "#008FFB",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: "Income",
            opposite: true,
            decimalsInFloat: 0,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#00E396",
            },
            labels: {
              maxWidth: "auto",
              style: {
                colors: "#00E396",
              },
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            title: {
              text: "Operating Cashflow (thousand crores)",
              style: {
                color: "#00E396",
              },
            },
          },
          {
            seriesName: "Revenue",
            opposite: true,
            decimalsInFloat: 0,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FEB019",
            },
            labels: {
              maxWidth: "auto",
              style: {
                colors: "#FEB019",
              },
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            title: {
              text: "Revenue (thousand crores)",
              style: {
                color: "#FEB019",
              },
            },
          },
        ],
        tooltip: {
          theme: "light",
        },
        legend: {
          horizontalAlign: "right",
          offsetY: 5,
          position: "bottom",
          show: true,
          labels: {
            useSeriesColors: true,
          },
        },
      },
    };
  },
  mounted() {
    this.getCollectionDetails();
    this.getCollectionStatistic();
    this.getNftStatistic();
    this.getCollectionCharts();
  },
  methods: {
    getCollectionDetails() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/marketplace/v2/api/dapp/" +
            this.$route.params.protocol +
            "/" +
            this.$route.params.category +
            "/" +
            this.$route.params.slug +
            "?currency=USD"
        )
        .then((res) => {
          this.collectionDetails = res.data;
          if (res.data != null) {
            axios
              .get(
                "https://api.opensea.io/api/v1/collection/" +
                  Object.values(res.data.slugs)[0]
              )
              .then((res) => {
                this.nftPrimaryAddress = res.data.collection;
                axios
                  .get(
                    "https://api-social-data.dinodapps.com/sales/v2.1/sale/day?limit=15&page=1&currency=USD&sort=priceInFiat&order=desc&collections=" +
                      res.data.collection.primary_asset_contracts[0]?.address
                  )
                  .then((res) => {
                    this.sales = res.data.results;
                  });
              });
            let str = Object.values(res.data.slugs)[0];
            let newStr = str.replace(/-/g, "");
            axios
              .get("https://api.opensea.io/api/v1/collection/" + newStr)
              .then((res) => {
                this.nftPrimaryAddress = res.data.collection;
                axios
                  .get(
                    "https://api-social-data.dinodapps.com/sales/v2.1/sale/day?limit=15&page=1&currency=USD&sort=priceInFiat&order=desc&collections=" +
                      res.data.collection.primary_asset_contracts[0]?.address
                  )
                  .then((res) => {
                    this.sales = res.data.results;
                  });
              });
            axios
              .get(
                "https://api-social-data.dinodapps.com/nft/v2.1/collection/day?dappId=" +
                  res.data.id +
                  "&currency=USD"
              )
              .then((res) => {
                this.statistic = res.data.results[0];
              });
          }
        });
    },
    getCollectionStatistic() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/marketplace/v2/api/dapp/" +
            this.$route.params.protocol +
            "/" +
            this.$route.params.category +
            "/" +
            this.$route.params.slug +
            "/statistic/day?currency=USD"
        )
        .then((res) => {
          this.collectionStats = res.data;
        });
    },
    getNftStatistic() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/marketplace/v2/api/dapp/" +
            this.$route.params.protocol +
            "/" +
            this.$route.params.category +
            "/" +
            this.$route.params.slug +
            "/statistic/day?currency=USD"
        )
        .then((res) => {
          this.nftStatistic = res.data;
        });
    },
    getCollectionCharts() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/marketplace/v2/api/dapp/" +
            this.$route.params.protocol +
            "/" +
            this.$route.params.category +
            "/" +
            this.$route.params.slug +
            "/chart/week?currency=USD"
        )
        .then((res) => {
          this.series = [
            {
              name: res.data.series[0].name,
              type: "line",
              data: res.data.series[0].data,
            },
            {
              name: res.data.series[1].name,
              type: "line",
              data: res.data.series[1].data,
            },
            {
              name: res.data.series[2].name,
              type: "line",
              data: res.data.series[2].data,
            },
          ];

          this.chartOptions = {
            title: {
              text: this.collectionDetails.name + " Statistic",
            },
            xaxis: {
              categories: res.data.xaxis,
              labels: {
                formatter: function (val) {
                  return moment(val).format("MMMM Do");
                },
              },
            },
            yaxis: [
              {
                labels: {
                  style: {
                    colors: "#008FFB",
                  },
                  formatter: function (val) {
                    if (val < 1e3) return val;
                    if (val >= 1e3 && val < 1e5)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e5 && val <= 1e6)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e6 && val <= 1e9)
                      return +(val / 1e6).toFixed(2) + "M";
                    if (val >= 1e9 && val <= 1e12)
                      return +(val / 1e9).toFixed(2) + "B";
                  },
                },
                title: {
                  style: {
                    color: "#008FFB",
                  },
                  text: res.data.series[0].name,
                },
              },
              {
                labels: {
                  style: {
                    colors: "#00E396",
                  },
                  formatter: function (val) {
                    if (val < 1e3) return val;
                    if (val >= 1e3 && val < 1e5)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e5 && val <= 1e6)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e6 && val <= 1e9)
                      return +(val / 1e6).toFixed(2) + "M";
                    if (val >= 1e9 && val <= 1e12)
                      return +(val / 1e9).toFixed(2) + "B";
                  },
                  opposite: true,
                },
                title: {
                  text: res.data.series[1].name,
                  style: {
                    color: "#00E396",
                  },
                },
                opposite: true,
              },
              {
                labels: {
                  style: {
                    colors: "#FEB019",
                  },
                  formatter: function (val) {
                    if (val < 1e3) return val;
                    if (val >= 1e3 && val < 1e5)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e5 && val <= 1e6)
                      return +(val / 1e3).toFixed(2) + "K";
                    if (val >= 1e6 && val <= 1e9)
                      return +(val / 1e6).toFixed(2) + "M";
                    if (val >= 1e9 && val <= 1e12)
                      return +(val / 1e9).toFixed(2) + "B";
                  },
                },
                title: {
                  text: res.data.series[2].name,
                  style: {
                    color: "#FEB019",
                  },
                },
                opposite: true,
              },
            ],
            legend: {
              horizontalAlign: "right",
            },
          };
        });
    },
  },
};
</script>

<style>
.custom-scroll {
  overflow-x: auto;
}
.custom-scroll::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none;
}
/* Optional: show position indicator in red */
.custom-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>